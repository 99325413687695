<template>
    <div>
        <!-- 搜索框-->
        <van-search v-model="value" placeholder="请输入搜索关键词"/>
        <!-- 列表-->
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <div ref="fresh" :style="{height:freshHeight +'px','overflow-y':'scroll','box-sizing': 'border-box'}">
                <div v-if="list.length<=0">
                    <van-empty image="error" description="没有数据"/>
                </div>
                <div v-else>
                    <van-list
                            v-model="loading"
                            :finished="finished"
                            finished-text="没有更多了"
                            @load="onLoad"
                    >
                        <customer-cell v-for="item in list" v-bind:key="item.id" :data="item"/>
                    </van-list>
                </div>
            </div>
        </van-pull-refresh>
      <!-- 添加按钮-->
<!--      <div style="position: absolute; top: 480px; right: 25px" @click="addCustomer()">-->
<!--        <circle-menu btn type="middle" :number="4" circle>-->
<!--        </circle-menu>-->
<!--      </div>-->
      <Add to="/FirstAddressContact"></Add>
        <!-- 底部导航栏-->
        <crm-tab-bar/>
    </div>
</template>

<script>
    import CrmTabBar from "@/components/CrmTabBar";
    import CustomerCell from '@/components/CustomerCell'
    import Add from '@/components/Add';
    export default {
        name: "CustomerList",
        components: {CrmTabBar, CustomerCell,Add},
        data() {
            return {
                list: [],
                value: '',
                //列表高度
                freshHeight: 0,
                //下拉标识
                isLoading: false,
                //上拉标识
                loading: false,
                //上拉加载完成
                finished: false,
            }
        },
        mounted() {
            this.freshHeight = document.documentElement.clientHeight - this.$refs.fresh.getBoundingClientRect().top - 50
            this.onRefresh();
        },
        methods: {
            // 下拉刷新
            onRefresh() {
                setTimeout(() => {
                    this.list = []
                    for (let i = 0; i < 10; i++) {
                        let data = {
                            id: Math.random(),
                            title: '中国城通国际贸易有限公司',
                            tag: '合作客户',
                            type: '货方客户',
                            nature: '贸易型',
                            user: '李阳',
                            to:'CustomerTwoShowHF',
                        }
                        this.list.push(data);
                    }

                    // 刷新状态结束
                    this.isLoading = false;
                    this.finished = false
                }, 1000);
            },
            // 上拉加载
            onLoad() {
                // 异步更新数据
                // setTimeout 仅做示例，真实场景中一般为 ajax 请求
                setTimeout(() => {
                    for (let i = 0; i < 10; i++) {
                        let data = {
                            id: Math.random(),
                            title: '中国城通国际贸易有限公司',
                            tag: '目标客户',
                            type: '货方客户',
                            nature: '贸易型',
                            user: '李阳',
                            to:'CustomerShowHF',
                        }
                        this.list.push(data);
                    }

                    // 加载状态结束
                    this.loading = false;

                    // 数据全部加载完成
                    if (this.list.length >= 40) {
                        this.finished = true;
                    }
                }, 1000);
            },
          //添加
          addCustomer() {
            this.$router.push("CustomerAddHF")
          }
        }
    }
</script>

<style scoped>

</style>
